import {usePrivy} from "@privy-io/react-auth";
import React from "react";
import {
  Link as RouterLink
} from "react-router-dom";
import Button from "@mui/material/Button";

export default function Navbar() {
  const { logout, user } = usePrivy();

  console.log(`User: ${JSON.stringify(user)}`)

  return (
    <div className="navbar-container">
      <div className="navbar-padding">
      </div>
      <div className="navbar">

        <RouterLink to={`/`} color="inherit">
          SA2
        </RouterLink>

        <div className="navbar__right-links">

          <Button variant="contained" onClick={logout}>Log Out</Button>
        </div>

      </div>
    </div>
  )
}
