import * as React from "react";

export default function LoginScreen() {
  return (
    <div className="login-screen">
        <div className="login-screen__background" style={{
        }}>
        </div>
      <p className="login-screen__admin-text">Dashboard</p>
    </div>
  )
}
