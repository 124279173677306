import React from 'react';
import { usePrivy } from '@privy-io/react-auth';
import './App.scss';
import AuthenticatedApp from "./AuthenticatedApp/AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp/UnauthenticatedApp";

function App() {
  const { ready, authenticated } = usePrivy();

  return (
    <>
      <div style={{zIndex:'99', color:'red',width:'100%',position:'fixed',textAlign:'center' }} hidden={process.env.REACT_APP_APP_ENV === 'production' ? true : false}>Staging</div>
      { ready && authenticated ? <AuthenticatedApp /> : <UnauthenticatedApp /> }
    </>
  );
}

export default App;
