import {usePrivy} from "@privy-io/react-auth";
import React from "react";
import {
  Link as RouterLink
} from "react-router-dom";
import {Button} from "@mui/material";

export default function Navbar() {
  const { login, user } = usePrivy();

  console.log(`User: ${JSON.stringify(user)}`)

  return (
    <div className="navbar-container">
      <div className="navbar-padding">
      </div>
      <div className="navbar">

        <RouterLink to={`/`} color="inherit"></RouterLink>
        <Button color="success" variant="contained" onClick={login}>Log in</Button>
      </div>
    </div>
  )
}
