import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../App.scss';
import Navbar from "./Navbar";
import LoginScreen from "./LoginScreen";

export default function UnauthenticatedApp() {

  return (
    <>
        <Router>
          <Navbar />

          <Routes>
            <Route path="/" element={<LoginScreen />} />
          </Routes>
        </Router>
    </>
  );
}
