import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import * as React from "react";
import {Input, TextField} from "@mui/material";
import {createInsights, getCampaigns} from "@unegma/sa2-lib-frontend";
import {getAccessToken} from "@privy-io/react-auth";

const ENDPOINT = `${process.env.REACT_APP_APP_ENV === 'production' ? process.env.REACT_APP_API_ENDPOINT : process.env.REACT_APP_API_ENDPOINT_STAGING}`;

/**
 * Gallery Screen
 *
 * @constructor
 */
export default function HomeScreen() {
  const [submitting, setSubmitting] = useState(false);
  const [campaignId, setCampaignId] = useState('');
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() =>{
    getAccessToken().then((token: any) => {
      getCampaigns(ENDPOINT, token, 'all').then((res: any) => {
        console.log('camp:', res)
        setCampaigns(res);
      });
    })
  }, [])


  const handleCreateInsights = async () => {

    setSubmitting(true);

    let response;

    console.log('here1')
    const token = await getAccessToken();
    response = await createInsights(ENDPOINT, token, campaignId, prompt);

    if (response && response.insight) {
      setResponse(response.insight)
    }

    if (response.responses) {
      console.log(response.responses)
    } else {
      alert('There was an error creating insights');
    }
    setSubmitting(false)
  }



  return (
    <>
      <div className="dashboard">

        {/*get all campaigns*/}

        {/*post request with campaignId for generating insights */}

        <div className="dashboard__area">

          <p>Generate Insights For:</p>

          <span>Campaign ID (see list below)</span>
            <br/>
          <Input placeholder="Campaign ID" value={campaignId} onChange={(e) => setCampaignId(e.target.value)}/>

          <br/>
          <br/>

          <span>Prompt</span>
            <br/>
          <TextField multiline={true} placeholder="Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)}/>

          <br/>
          <br/>

          <Button disabled={submitting} variant="contained" onClick={() => handleCreateInsights()}>Generate Insights</Button>

          <br/>
          <br/>

          <p>Response</p>
          <p style={{color: 'green'}}>{response}</p>


          <p>All Campaigns:</p>

          { campaigns && campaigns.map((campaign: any) => {
            return (
              <div key={campaign.uuid}>
                <span>{campaign.question}<br/></span>
                <span>{campaign.uuid}<br/></span>
                <span>{campaign.userId}<br/></span>
                <br/>
              </div>
            )
          })}

        </div>

      </div>
    </>
  )
}
