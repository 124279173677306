import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from "./pages/HomeScreen";
import '../App.scss';
import Navbar from "./components/Navbar";

export default function AuthenticatedApp() {

  return (
    <>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<HomeScreen />} />
        </Routes>
      </Router>
    </>
  );
}
